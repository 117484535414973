let _selector = $('html, body'),
    _slideractive =$("section.active"),
    _index = 0,
    preventscroll=false;


   
_selector.bind('scroll DOMMouseScroll mousewheel', function (e) {
  let target = e.currentTarget,
  scrollTop = target.scrollTop || window.pageYOffset,
  originaldetails = e.originalEvent.detail,
  scrollHeight = target.scrollHeight || document.body.scrollHeight,
  delta = e.originalEvent.deltaY,
  _sliderHeight =$("section.active").outerHeight(),
  activeslide1 = $("section.active").hasClass("section1"),
  activeslide2 = $("section.active").hasClass("section2"),
  activeslide3 = $("section.active").hasClass("section3"),
  activeslide4 = $("section.active").hasClass("section4"),
  activeslide5 = $("section.active").hasClass("section5"),
  activeslide6 = $("section.active").hasClass("section6"),
  activeslide7 = $("section.active").hasClass("section7");

  if(activeslide1) {
    $(".slide-dots li").removeClass("active");
    $(".li-one").addClass("active");
  }

  if(activeslide2) {
    $(".slide-dots li").removeClass("active");
    $(".li-two").addClass("active");
  }
  if(activeslide3) {
    $(".slide-dots li").removeClass("active");
    $(".li-three").addClass("active");
  }
  if(activeslide4) {
    $(".slide-dots li").removeClass("active");
    $(".li-four").addClass("active");
  }
  if(activeslide5) {
    $(".slide-dots li").removeClass("active");
    $(".li-five").addClass("active");
  }
  if(activeslide6) {
    $(".slide-dots li").removeClass("active");
    $(".li-six").addClass("active");
  }
  if(activeslide7) {
    $(".slide-dots li").removeClass("active");
    $(".li-seven").addClass("active");
  }



  //console.log(e);
  if (delta > 0 || originaldetails < 0) {
    --_index;
    //scrolldown
    console.log("down");
    if(activeslide1) {
      if(scrollTop >= _sliderHeight) {
        $("section").removeClass("active fixed relative");
        $(".section1").addClass("relative");
        $(".section2").addClass("active");
        $(".section3").addClass("fixed");
        $(".section4").addClass("fixed");
        $(".section5").addClass("fixed");
        $(".section6").addClass("fixed");
        $(".section7").addClass("fixed");
      }

    }
    if(activeslide2) {
      _sliderHeight = $(".section1").outerHeight() + $(".section2").outerHeight();
      if(scrollTop >= _sliderHeight) {
        $("section").removeClass("active fixed relative");
        $(".section1").addClass("relative");
        $(".section2").addClass("relative");
        $(".section3").addClass("active");
        $(".section4").addClass("fixed");
        $(".section5").addClass("fixed");
        $(".section6").addClass("fixed");
        $(".section7").addClass("fixed");
      }

    }
    if(activeslide3) {
      _sliderHeight = $(".section1").outerHeight() + $(".section2").outerHeight() + $(".section3").outerHeight();
      if(scrollTop >= _sliderHeight) {
        $("section").removeClass("active fixed relative");
        $(".section1").addClass("relative");
        $(".section2").addClass("relative");
        $(".section3").addClass("relative");
        $(".section4").addClass("active");
        $(".section5").addClass("fixed");
        $(".section6").addClass("fixed");
        $(".section7").addClass("fixed");
      }

    }
    if(activeslide4) {
      _sliderHeight = $(".section1").outerHeight() + $(".section2").outerHeight() + 
                      $(".section3").outerHeight() + $(".section4").outerHeight();
      if(scrollTop >= _sliderHeight) {
        $("section").removeClass("active fixed relative");
        $(".section1").addClass("relative");
        $(".section2").addClass("relative");
        $(".section3").addClass("relative");
        $(".section4").addClass("relative");
        $(".section5").addClass("active");
        $(".section6").addClass("fixed");
        $(".section7").addClass("fixed");
      }

    }
    if(activeslide5) {
      _sliderHeight = $(".section1").outerHeight() + $(".section2").outerHeight() + 
                      $(".section3").outerHeight() + $(".section4").outerHeight()+ $(".section5").outerHeight();
      if(scrollTop >= _sliderHeight) {
        $("section").removeClass("active fixed relative");
        $(".section1").addClass("relative");
        $(".section2").addClass("relative");
        $(".section3").addClass("relative");
        $(".section4").addClass("relative");
        $(".section5").addClass("relative");
        $(".section6").addClass("active");
        $(".section7").addClass("fixed");
      }

    }
    if(activeslide6) {
      _sliderHeight = $(".section1").outerHeight() + $(".section2").outerHeight()+ 
                      $(".section3").outerHeight() + $(".section4").outerHeight() +
                      $(".section5").outerHeight() + $(".section6").outerHeight();
      if(scrollTop >= _sliderHeight) {
        $("section").removeClass("active fixed relative");
        $(".section1").addClass("relative");
        $(".section2").addClass("relative");
        $(".section3").addClass("relative");
        $(".section4").addClass("relative");
        $(".section5").addClass("relative");
        $(".section6").addClass("relative");
        $(".section7").addClass("active");
      }

    }

  
  }
  if (delta < 0 || originaldetails > 0) {
    ++_index;
        //scrollup
       
console.log("up");
        if(activeslide2) {
          var sliderheight1 = $(".section1").outerHeight();
          var sliderheight2 = $(".section2").outerHeight();
          var sliderheight3 = $(".section3").outerHeight();
          var sliderheight4 = $(".section4").outerHeight();
          var sliderheight5 = $(".section5").outerHeight();
          var sliderheight6 = $(".section6").outerHeight();
          var sliderheight7 = $(".section7").outerHeight();
          console.log("sliderheight1=>"+ sliderheight1);
          console.log("sliderheight2=>"+ sliderheight2);
          console.log("sliderheight3=>"+ sliderheight3);
          console.log("sliderheight4=>"+ sliderheight4);
          console.log("sliderheight5=>"+ sliderheight5);
          console.log("sliderheight6=>"+ sliderheight6);
          console.log("sliderheight7=>"+ sliderheight7);


          var sliderheight2 = sliderheight1;
          if(scrollTop <=  sliderheight2){
            $("section").removeClass("active fixed relative");
            $(".section1").addClass("active");
            $(".section2").addClass("fixed");
            $(".section3").addClass("fixed");
            $(".section4").addClass("fixed");
            $(".section5").addClass("fixed");
            $(".section6").addClass("fixed");
            $(".section7").addClass("fixed");
          }
        }
        if(activeslide3) {
          var sliderheight1 = $(".section1").outerHeight();
          var sliderheight2 = $(".section2").outerHeight();
          var sliderheight3 = $(".section3").outerHeight();
          var sliderheight4 = $(".section4").outerHeight();
          var sliderheight5 = $(".section5").outerHeight();
          var sliderheight6 = $(".section6").outerHeight();
          var sliderheight7 = $(".section7").outerHeight();
          console.log("sliderheight1=>"+ sliderheight1);
          console.log("sliderheight2=>"+ sliderheight2);
          console.log("sliderheight3=>"+ sliderheight3);
          console.log("sliderheight4=>"+ sliderheight4);
          console.log("sliderheight5=>"+ sliderheight5);
          console.log("sliderheight6=>"+ sliderheight6);
          console.log("sliderheight7=>"+ sliderheight7);


          var sliderheight3 = sliderheight1 + sliderheight2;
          if(scrollTop <=  sliderheight3){
            $("section").removeClass("active fixed relative");
            $(".section1").addClass("relative");
            $(".section2").addClass("active");
            $(".section3").addClass("fixed");
            $(".section4").addClass("fixed");
            $(".section5").addClass("fixed");
            $(".section6").addClass("fixed");
            $(".section7").addClass("fixed");
          }
        }
        if(activeslide4) {
          var sliderheight1 = $(".section1").outerHeight();
          var sliderheight2 = $(".section2").outerHeight();
          var sliderheight3 = $(".section3").outerHeight();
          var sliderheight4 = $(".section4").outerHeight();
          var sliderheight5 = $(".section5").outerHeight();
          var sliderheight6 = $(".section6").outerHeight();
          var sliderheight7 = $(".section7").outerHeight();
          console.log("sliderheight1=>"+ sliderheight1);
          console.log("sliderheight2=>"+ sliderheight2);
          console.log("sliderheight3=>"+ sliderheight3);
          console.log("sliderheight4=>"+ sliderheight4);
          console.log("sliderheight5=>"+ sliderheight5);
          console.log("sliderheight6=>"+ sliderheight6);
          console.log("sliderheight7=>"+ sliderheight7);


          var sliderheight4 = sliderheight1 + sliderheight2 + sliderheight3;
          if(scrollTop <=  sliderheight4){
            $("section").removeClass("active fixed relative");
            $(".section1").addClass("relative");
            $(".section2").addClass("relative");
            $(".section3").addClass("active");
            $(".section4").addClass("fixed");
            $(".section5").addClass("fixed");
            $(".section6").addClass("fixed");
            $(".section7").addClass("fixed");
          }
        }

        if(activeslide5) {
          var sliderheight1 = $(".section1").outerHeight();
          var sliderheight2 = $(".section2").outerHeight();
          var sliderheight3 = $(".section3").outerHeight();
          var sliderheight4 = $(".section4").outerHeight();
          var sliderheight5 = $(".section5").outerHeight();
          var sliderheight6 = $(".section6").outerHeight();
          var sliderheight7 = $(".section7").outerHeight();
          console.log("sliderheight1=>"+ sliderheight1);
          console.log("sliderheight2=>"+ sliderheight2);
          console.log("sliderheight3=>"+ sliderheight3);
          console.log("sliderheight4=>"+ sliderheight4);
          console.log("sliderheight5=>"+ sliderheight5);
          console.log("sliderheight6=>"+ sliderheight6);
          console.log("sliderheight7=>"+ sliderheight7);


          var sliderheight5 = sliderheight1 + sliderheight2 + sliderheight3 +sliderheight4;
          if(scrollTop <=  sliderheight5){
            $("section").removeClass("active fixed relative");
            $(".section1").addClass("relative");
            $(".section2").addClass("relative");
            $(".section3").addClass("relative");
            $(".section4").addClass("active");
            $(".section5").addClass("fixed");
            $(".section6").addClass("fixed");
            $(".section7").addClass("fixed");
          }
        }



        if(activeslide6) {
         var sliderheight1 = $(".section1").outerHeight();
          var sliderheight2 = $(".section2").outerHeight();
          var sliderheight3 = $(".section3").outerHeight();
          var sliderheight4 = $(".section4").outerHeight();
          var sliderheight5 = $(".section5").outerHeight();
          var sliderheight6 = $(".section6").outerHeight();
          var sliderheight7 = $(".section7").outerHeight();

          var sliderheight6 = sliderheight1 + sliderheight2 + sliderheight3 +sliderheight4 + sliderheight5;
          if(scrollTop <=  sliderheight6){
            $("section").removeClass("active fixed relative");
            $(".section1").addClass("relative");
            $(".section2").addClass("relative");
            $(".section3").addClass("relative");
            $(".section4").addClass("relative");
            $(".section5").addClass("active");
            $(".section6").addClass("fixed");
            $(".section7").addClass("fixed");
          }
        }


        if(activeslide7) {

            $("section").removeClass("active fixed relative");
            $(".section1").addClass("relative");
            $(".section2").addClass("relative");
            $(".section3").addClass("relative");
            $(".section4").addClass("relative");
            $(".section5").addClass("relative");
            $(".section6").addClass("active");
            $(".section7").addClass("fixed");
        }
  }
});

